<section class="manage">
  <article class="options">
    <div class="options__head-title">
      {{'options' | translate}}
    </div>

    <div class="options__main-box">
      <div class="options__action-box">
        <div class="options__text-box">
          <div class="options__title">{{'assignShiftDirectly' | translate}}</div>
          <div class="options__subtitle">{{'assignShiftDirectlyText' | translate}}</div>
        </div>
        <div class="options__toggle-button" (click)="changedType(1)">
          <div class="options__toggle-field" [ngClass]="{'selected-option': typeSend == 1}">
            <div class="options__toggle-circle" [ngClass]="{'selected-circle': typeSend == 1}"></div>
          </div>
        </div>
      </div>
      <div class="options__action-box">
        <div class="options__text-box">
          <div class="options__title">{{'sendInvite' | translate}}</div>
          <div class="options__subtitle">{{'sendInviteText' | translate}}</div>
        </div>
        <div class="options__toggle-button" (click)="changedType(2)">
          <div class="options__toggle-field" [ngClass]="{'selected-option': typeSend == 2}">
            <div class="options__toggle-circle" [ngClass]="{'selected-circle': typeSend == 2}"></div>
          </div>
        </div>
      </div>
      <div class="options__action-box">
        <div class="options__text-box">
          <div class="options__title">{{'sendRequest' | translate}}</div>
          <div class="options__subtitle">{{'sendRequestText' | translate}}</div>
        </div>
        <div class="options__toggle-button" (click)="changedType(3)">
          <div class="options__toggle-field" [ngClass]="{'selected-option': typeSend == 3}">
            <div class="options__toggle-circle" [ngClass]="{'selected-circle': typeSend == 3}"></div>
          </div>
        </div>
      </div>
      <div class="options__action-box">
        <div class="options__text-box options__text-box--height">
          <div class="options__title options__title-margin">{{'addMessage' | translate}}</div>
        </div>
        <textarea class="form__text-area" [(ngModel)]="message" (change)="messageChanged()"></textarea>
      </div>
    </div>
  </article>

  <article class="filter">
    <div class="options__head-title">
      {{'filters' | translate}}
    </div>

    <div class="filter__box">
      <div class="filter__input-box">
        <div class="section-select__select-box" (clickOutside)="showContracts = false">
          <input class="section-select__select" (click)="showAllContracts()"
                 [(ngModel)]="selectedContract"
                 readonly
                 placeholder="{{'allContracts' | translate}}"

          />
          <div class="dropdown dropdown--shifts noMaxHeight" *ngIf="showContracts">
            <div class="option" (click)="selectContract({id: -1}, $event)">
              <span>{{'allContracts' | translate}}</span>
            </div>
            <mat-checkbox class="checkBox-input" (change)="selectContract({id: -1}, $event)"
                          [checked]="allContractsChecked">
            </mat-checkbox>
            <div class="option" *ngFor="let contract of contracts" (click)="selectContract(contract, $event)">
              <div>
                <span>{{contract.title}}</span>
              </div>
              <mat-checkbox class="checkBox-input" [checked]="contractsIsChecked(contract)"></mat-checkbox>
            </div>
          </div>
        </div>

        <div class="section-select__select-box" (clickOutside)="showCompetences = false">
          <input class="section-select__select" (click)="showAllCompetences()"
                 [(ngModel)]="selectedCompetence"
                 readonly
                 placeholder="{{'allCompetences' | translate}}"
          />
          <div class="dropdown dropdown--shifts noMaxHeight" *ngIf="showCompetences">
            <div class="option" (click)="selectCoompetence({id: -1}, $event)">
              <span>{{'allCompetences' | translate}}</span>
            </div>
            <mat-checkbox class="checkBox-input" (change)="selectCoompetence({id: -1}, $event)"
                          [checked]="allCompetencesChecked"></mat-checkbox>
            <div class="option" *ngFor="let competence of competences"
                 (click)="selectCoompetence(competence, $event)">
              <div>
                <span>{{competence.title}}</span>
              </div>
              <mat-checkbox class="checkBox-input" [checked]="competencesIsChecked(competence)"></mat-checkbox>
            </div>
          </div>
        </div>

        <div class="section-select__select-box">
          <input class="section-select__select" (click)="showAllSorts()"
                 (clickOutside)="showSort = false"
                 [(ngModel)]="selectedSort"
                 readonly
          />
          <div class="dropdown dropdown--shifts" *ngIf="showSort">
            <div class="option" *ngFor="let sort of sorts"
                 (click)="selectSort(sort, false)">
              <div>{{sort.title | translate}}</div>
              <img *ngIf="sort.id === sortId" class="shift-check-mark"
                   src="../../../assets/images/Checkmark.svg"
                   alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div class="filter__input-btn-box" (click)="tempUser()">
        <button class="button button--primary filter__input-button">{{'tempUser' | translate}}</button>
      </div>
    </div>
  </article>
  <div class="for-table">
    <table class="table-cal">
      <thead>
      <tr class="table-cal__tr-th">
        <th class="table-cal__image-th"></th>
        <th style="width: 201px; padding-left: 20px">{{'name' | translate}}</th>
        <th style="width: 162px;">{{'contract' | translate}}</th>
        <th style="width: 150px;">{{'competence' | translate}}</th>
        <th style="width: 160px; text-align: center">{{'availability' | translate}}</th>
        <th style="text-align: center">{{'status' | translate}}</th>
        <td class="checkBox">
          <mat-checkbox class="example-margin" (click)="setAll($event)" [disabled]="typeSend == 1"
                        [ngClass]="{'disabled-checkbox':  typeSend != 2 && typeSend != 3}"></mat-checkbox>
        </td>
      </tr>
      </thead>
      <tbody>
      <tr class="tr" *ngFor="let employee of employees">
        <td class="user-image-td">
          <img class="user-image" src="assets/images/profile_white.svg" alt=""
               *ngIf="!employee.profilePicture || employee.profilePicture == ''">
          <img class="user-image" *ngIf="employee.profilePicture && employee.profilePicture != ''"
               [src]="employee.profilePicture" alt="">
        </td>
        <td class="table__name-td" style="padding-left: 20px"
            (click)="openUser(employee.id)">{{employee.name}}</td>
        <td class="table__positions-td"
            (click)="openUser(employee.id)">{{eraseCommaa(employee.contracts)}}</td>
        <td class="table__competences-td"
            (click)="openUser(employee.id)">{{eraseCommaa(employee.competences)}}</td>
        <td class="table__roles-td"
            (click)="openUser(employee.id)">

          <div class="for-availabilities" *ngIf="shift">
            <div class="availability-block" [ngClass]="{'isFilled': employee.specificShifts[0]}">

            </div>
            <div class="availability-block" [ngClass]="{'isFilled': employee.specificShifts[1]}">

            </div>
            <div class="availability-block now-block" [ngClass]="{'isFilled': employee.specificShifts[-1]}">

            </div>
            <div class="availability-block" [ngClass]="{'isFilled': employee.specificShifts[2]}">

            </div>
            <div class="availability-block" [ngClass]="{'isFilled': employee.specificShifts[3]}">

            </div>
          </div>
          <span *ngIf="!shift">{{getUserStatus(employee)}} </span>

        </td>
        <td class="table__rowStatus-td" style="text-align: center" [routerLink]="['user/', employee.id]">
          <span class="status" *ngIf="hasStatus(employee) && !checkActiveApplication(employee)"
                [ngClass]="{'redText': employee.hasAbsence == 1}">{{hasStatus(employee) | translate}}</span>
        </td>
        <td class="table__rowStatus-td td-buttons" *ngIf="checkActiveApplication(employee)">
          <div class="for-app-buttons">
            <div class="table-button table-button--decline-button"
                 (click)="declineApplication(checkActiveApplication(employee))">
              <span class="button-text">
              {{'decline' | translate}}
                </span>
            </div>
            <div class="table-button table-button--accept-button"
                 (click)="acceptApplication(checkActiveApplication(employee))">
                <span class="button-text">
              {{'accept' | translate}}
                </span>
            </div>
          </div>
        </td>
        <td class="table__checkbox-box checkBox " *ngIf="!checkActiveApplication(employee)">
          <mat-checkbox class="example-margin"
                        [ngClass]="{'disabled-checkbox': typeSend != 1 && typeSend != 2 && typeSend != 3}"
                        (change)="selection(employee)" (click)="shouldPrevent($event)"
                        [checked]="isChecked(employee)"></mat-checkbox>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</section>

<div style="width: 1190px;">
  <app-loading class="spinner" *ngIf="loading"></app-loading>
</div>
